<template>
  <div>
    <v-card flat tile>
      <v-overlay :value="overlay" absolute>
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-toolbar flat color="white" class="mt-3">
        <v-toolbar-title>
          <v-icon class="mb-1">mdi-airplane-check</v-icon>
        </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
          append-icon="mdi-magnify"
          v-model="fincaSel"
          dense
          label="Finca"
          class="mx-4"
          text
          clearable
          @click:append="cargarReservas(true)"
          @keyup.enter="cargarReservas(true)"
        >
        </v-text-field>

        <v-text-field
          append-icon="mdi-magnify"
          v-model="clienteSel"
          dense
          label="cliente"
          class="mx-4"
          text
          clearable
          @click:append="cargarReservas(true)"
          @keyup.enter="cargarReservas(true)"
        >
        </v-text-field>

        <v-text-field
          append-icon="mdi-magnify"
          v-model="subClienteSel"
          dense
          label="sub cliente"
          class="mx-4"
          text
          clearable
          @click:append="cargarReservas(true)"
          @keyup.enter="cargarReservas(true)"
        >
        </v-text-field>

        <v-select
          v-model="estadoSel"
          dense
          label="estado"
          class="mx-4"
          text
          :items="estados"
          clearable
          @change="cargarReservas(true)"
        >
        </v-select>

        <v-text-field
          append-icon="mdi-magnify"
          v-model="fechaSel"
          dense
          label="Fecha"
          class="mx-4"
          text
          type="date"
          clearable
          @click:append="cargarReservas(true)"
          @keyup.enter="cargarReservas(true)"
          @change="cargarReservas(true)"
        >
        </v-text-field>

        <v-spacer></v-spacer>
      </v-toolbar>
      <v-toolbar text color="white" flat>
        <v-btn class="primary" small @click="abrirConfirmarReserva()">
          <v-icon>mdi-check</v-icon>
          Confirmar
        </v-btn>
      </v-toolbar>

      <v-data-table
        :headers="headerReservas"
        :items="reservasPorConf"
        item-key="RESERVA_ID"
        v-model="reservasSel"
        :search="buscar"
        :items-per-page="itemsPerPage"
        class="elevation-1 ml-4"
        :show-select="true"
        hide-default-footer
      >
        <template v-slot:item.opciones="{ item }">
          <v-btn small text dense @click="verReserva(item)">
            <v-icon color="info">mdi-eye</v-icon>
          </v-btn>
        </template>

        <template slot="body.append">
          <tr class="pink--text">
            <th colspan="6" class="title">
              FL: {{ suma2("FUL_RES") }} | MD: {{ suma2("HB_RES") }} | QT:
              {{ suma2("QT_RES") }} | TR: {{ suma2("TR_RES") }} | SX:
              {{ suma2("SX_RES") }} | OC: {{ suma2("OC_RES") }} | DS:
              {{ suma2("DS_RES") }}
            </th>

            <th colspan="3" class="title" align="right">
              TOTAL CAJAS: {{ suma("CAJAS_RES") }}
            </th>
            <th class="title" colspan="2">Items:{{ totalItems }}</th>
            <th class="title"></th>
            <th class="title"></th>
          </tr>
        </template>

        <template v-slot:footer>
          <v-pagination
            class="mt-10"
            v-model="currentPage"
            :length="pageCount"
            @input="handlePageChange"
            total-visible="10"
          ></v-pagination>
        </template>
      </v-data-table>
    </v-card>

    <!-- DIALOG CONFIRMAR RESERVA -->
    <v-dialog v-model="dialogConfReserva" persistent max-width="550px">
      <v-card>
        <v-card-title>
          <v-icon class="mx-1">mdi-check</v-icon>
          <span class="headline">Confirmar reserva</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="formConfReserva">
              <v-row>
                <v-col class="pt-0 pb-0" cols="12">
                  <v-autocomplete
                    v-model="guiaSel"
                    small
                    item-text="AWB"
                    item-value="CABECERA_ID"
                    :items="guiasAbiertas"
                    clearable
                    label="Seleccione una guia"
                  >
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="data.item.CLIENTE_NOMBRE"
                          ></v-list-item-title>
                          <v-list-item-subtitle
                            v-html="data.item.FECHA"
                          ></v-list-item-subtitle>
                          <v-list-item-subtitle
                            v-html="data.item.AWB"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="9">
                  <v-btn
                    :loading="btnTransLoading"
                    color="primary"
                    small
                    @click="confirmarReserva"
                  >
                    <v-icon>mdi-check</v-icon>
                    Confirmar
                  </v-btn>
                </v-col>
                <v-col cols="3">
                  <v-btn
                    :loading="btnTransLoading"
                    color="primary"
                    small
                    text
                    @click="dialogConfReserva = false"
                  >
                    <v-icon>mdi-close</v-icon>
                    Cancelar
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- DIALOG DATOS DE LA RESERVA -->
    <v-dialog v-model="dialogDatosReserva" persistent max-width="550px">
      <v-card>
        <v-card-title>
          <v-icon class="mx-1">mdi-check</v-icon>
          <span class="headline">Datos reserva</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="formDatosReserva">
              <v-row v-if="detalleReserva.IDENT_BIENTES == 'CONS'">
                <v-col class="pt-0 pb-0" cols="12"> Cliente final </v-col>
                <v-col class="pt-0 pb-0" cols="12">
                  <v-data-table
                    :items="itemsReserva"
                    :headers="headerItems"
                    class="elevation-1 ml-4"
                    hide-default-footer
                  >
                  </v-data-table>
                </v-col>
              </v-row>
              <v-row class="py-0">
                <v-col cols="12" class="py-0"><h4>Detalle piezas</h4></v-col>
              </v-row>

              <v-row v-if="detalleReserva.IDENT_BIENTES != 'CONS'" class="pt-2">
                <v-col cols="2">
                  <v-text-field
                    small
                    label="FL"
                    v-model="parseFloat(reserva.FULL_RES).toFixed(0)"
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    small
                    label="HB"
                    v-model="parseFloat(reserva.HB_RES).toFixed()"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    label="QT"
                    v-model="parseFloat(reserva.QT_RES).toFixed()"
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    label="TR"
                    v-model="parseFloat(reserva.TR_RES).toFixed()"
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    label="OC"
                    v-model="parseFloat(reserva.OC_RES).toFixed()"
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    label="DS"
                    v-model="parseFloat(reserva.DS_RES).toFixed()"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pt-0 pb-0" cols="12"><h4>Guias</h4></v-col>
                <v-col class="pt-0 pb-0" cols="6">
                  <v-text-field
                    v-model="detalleReserva.AWB"
                    label="AWB"
                    readonly="true"
                    class="mx-4"
                    text
                  ></v-text-field>
                </v-col>
                <v-col class="pt-0 pb-0" cols="6">
                  <v-text-field
                    v-model="detalleReserva.NUM_GUIA_FINCA"
                    label="HAWB"
                    readonly="true"
                    class="mx-4"
                    text
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pt-0 pb-0" cols="12"><h4>Usuario</h4></v-col>
                <v-col class="pt-0 pb-0" cols="6">
                  <v-text-field
                    v-model="reserva.INGRESADO_POR"
                    label="Ingresado por"
                    readonly="true"
                    class="mx-4"
                    text
                  ></v-text-field>
                </v-col>
                <v-col class="pt-0 pb-0" cols="6">
                  <v-text-field
                    v-model="reserva.FECHA_CREADO"
                    label="Fecha de ingreso"
                    readonly="true"
                    class="mx-4"
                    text
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pt-0 pb-0" cols="12"> </v-col>
                <v-col class="pt-0 pb-0" cols="6">
                  <v-text-field
                    v-model="reserva.CONFIRMADO_POR"
                    label="Confirmado por"
                    readonly="true"
                    class="mx-4"
                    text
                  ></v-text-field>
                </v-col>
                <v-col class="pt-0 pb-0" cols="6">
                  <v-text-field
                    v-model="reserva.FECHA_CONFIRMACION"
                    label="Fecha de confirmacion"
                    readonly="true"
                    class="mx-4"
                    text
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
            <v-row>
              <v-col cols="9"> </v-col>
              <v-col cols="3">
                <v-btn
                  :loading="btnTransLoading"
                  color="primary"
                  small
                  @click="dialogDatosReserva = false"
                >
                  <v-icon>mdi-close</v-icon>
                  Cerrar
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import moment from "moment";
import Vue from "vue";

export default {
  name: "ConfirmarReservasComponent",

  data: () => ({
    headerReservas: [
      { text: "Destino", align: "start", value: "DEST_PAIS" },
      { text: "Fecha de vuelo", align: "start", value: "HCOORD_FECHA" },
      { text: "Finca", align: "start", value: "FINCA_NOMBRE" },
      { text: "Cliente", align: "start", value: "CLIENTE" },
      { text: "Sub cliente", align: "start", value: "SCLI_NOMBRE" },
      { text: "Cajas", align: "start", value: "CAJAS_RES" },
      { text: "Observaciones", value: "OBSERVACIONES" },
      { text: "Estado", align: "start", value: "ESTADO_RES" },
      //{ text: "Reservas", value: "data-table-expand", align: "center" },
      { text: "Opciones", align: "start", value: "opciones" },
    ],
    buscar: "",
    reservasPorConf: [],
    reservasSel: [],
    overlay: false,
    totalItems: 1,
    currentPage: 1,
    itemsPerPage: 20,
    guiasAbiertas: [],
    estados: ["CONFIRMADA", "POR CONFIRMAR", "CANCELADA"],
    estadoSel: "",
    clienteSel: "",
    subClienteSel: "",
    fincaSel: "",
    fechaSel: "",
    dialogConfReserva: false,
    dialogDatosReserva: false,
    btnTransLoading: false,

    headerItems: [
      { text: "Cliente", align: "start", value: "CLIENTE_FINAL_NOMBRE" },
      { text: "Variedad", align: "start", value: "PRO_DESC" },
      { text: "Tipo de caja", align: "start", value: "TCAJA_ID" },
      { text: "Cajas", align: "start", value: "CAJAS_RES" },
    ],
    itemsReserva: [],
    detalleReserva: {},
    reserva: [],
    guiaSel: "",
    requiredRule: [
      (v) =>
        (v != "" && v != null && v != undefined) || "El campo es requerido",
    ],
  }),
  watch: {},
  computed: {
    ...mapState("master", ["user", "loadingTable"]),

    ...mapState("access", ["loggedIn"]),
  },
  methods: {
    ...mapMutations("master", [
      "setUrl",
      "setLoadingBtn",
      "setLoadingTable",
      "setTitleToolbar",
    ]),

    ...mapActions("master", ["requestApi", "alertNotification"]),

    cargarReservas(resetPage) {
      this.setLoadingTable(true);
      //console.log(this.destinoId.DEST_CIUDAD);
      if (resetPage === true) {
        this.currentPage = 1;
      }
      this.setUrl("api/reservas-por-confirmar");
      this.requestApi({
        method: "GET",
        data: {
          fincaSel: this.fincaSel,
          clienteSel: this.clienteSel,
          subClienteSel: this.subClienteSel,
          estadoSel: this.estadoSel,
          fechaSel: this.fechaSel,
          page: this.currentPage,
          itemsPerPage: this.itemsPerPage,
        },
      })
        .then((res) => {
          this.reservasPorConf = res.data.reservas;
          this.guiasAbiertas = res.data.guiasAbiertas;
          this.pageCount = res.data.pageCount;
          this.totalItems = res.data.totalItems;
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    abrirConfirmarReserva() {
      this.dialogConfReserva = true;
    },
    confirmarReserva() {
      this.setUrl("api/confirmar-reserva");
      this.requestApi({
        method: "POST",
        data: {
          guia: this.guiaSel,
          reservas: this.reservasSel,
        },
      })
        .then((res) => {
          this.alertNotification({ param: { html: res.data.msg } });
          this.reservasSel = [];
          this.dialogConfReserva = false;
          this.cargarReservas();
        })
        .then(() => {
          //this.setLoadingTable(false);
          this.reservasSel = [];
          this.dialogConfReserva = false;
        });
    },
    verReserva(item) {
      this.dialogDatosReserva = true;
      this.setUrl("api/cargar-reserva-cf");
      this.requestApi({
        method: "GET",
        data: {
          RESERVA_ID: item.RESERVA_ID,
        },
      })
        .then((res) => {
          this.reserva = res.data.reserva;
          this.itemsReserva = res.data.items;
          this.detalleReserva = res.data.detalle;
        })
        .then(() => {});
    },
    suma(col) {
      return this.reservasPorConf
        .reduce(function (a, b) {
          //if (b["signo"] == "pos") {
          return parseFloat(a, 10) + parseFloat(b[col] || 0, 10);

          //} else {
          //  return a + (b[col] * -1 || 0);
          //}
        }, 0)
        .toFixed(2);
    },
    suma2(col) {
      return this.reservasPorConf
        .reduce(function (a, b) {
          //if (b["signo"] == "pos") {
          return parseFloat(a, 10) + parseFloat(b[col] || 0, 10);

          //} else {
          //  return a + (b[col] * -1 || 0);
          //}
        }, 0)
        .toFixed(0);
    },
    handlePageChange() {
      this.cargarReservas();
    },
  },

  mounted() {
    document.title = "Sistema de carga";
    this.setTitleToolbar("CONFIRMAR RESERVAS");
    this.cargarReservas();
  },
};
</script>
